.iconButton {
    background-color: #EAEDF0;
    min-width: 35px;
    color: #2C476C;
    border: none; /* Remove border if not needed */
    border-radius: 7px;
    transition: background-color 0.3s, color 0.3s;
}

.iconButton:hover {
    background-color: #EAEDF0; /* Slightly darker on hover for default state */
}

.iconButtonToggled {
    background-color: #036147 !important; /* Ensures higher specificity */
    color: #FFFFFF !important;
}
/**/
.iconButtonToggled:hover {
    background-color: #02573d; /* Slightly darker on hover for toggled state */
}

@media( max-width: 1024px) {
    .iconButton span {
        font-size: 14px !important;
        font-family: 'Noto Sans Armenian', sans-serif !important;
        font-weight: 400 !important;
        line-height: 32px !important;
        letter-spacing: 1px !important;
        margin: 0 !important;
        padding: 0 !important;
        text-align: left !important;
        text-underline-position: from-font !important;
        text-decoration-skip-ink: none !important;
    }
}
