.card > * {
    font-family: 'Noto Sans Armenian', sans-serif !important;
}

    .card {
    width: 100%;
    max-width: 400px;
    max-height: 270px;
    height: 100%;
    border-radius: 10px;
    background-color: #fff;
    color: #2c476c;
    display: flex;
    flex-direction: column;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    flex-wrap: nowrap;
    width: 100%;
}

.iconWrapper {
    background-color: #2c476c;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.iconImage {
    width: 20px;
    height: 20px;
}

.title {
    margin-bottom: 0 !important;
    padding: 0;
    color: #2c476c;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 70%;
    font-family: 'Noto Sans Armenian';
    font-size: 14px;
    font-weight: 600;
}

.tooltip {
    background-color: #2c476c;
    color: #fff;
    padding: 5px;
    border-radius: 5px;
    text-align: left;
}

.tooltipItem {
    cursor: pointer;
}

.tooltipDivider {
    margin: 5px 0;
    border-color: #fff;
}

.tooltipButtonWrapper {
    width: 30px;
    height: fit-content;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.ellipsisIcon {
    width: fit-content;
    color: #2c476c;
    cursor: pointer;
    pointer-events: auto;
    height: max(20px, calc(100vh/107));
}

.divider {
    margin: 10px 0;
    border-width: 2px;
    border-color: #FF6539;
}

.content {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    overflow: hidden;
}

.imageWrapper {
    width: 65px;
    height: 55px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}

.imageWrapper, .image {
    width: 80px;
    height: 65px;
    border-radius: 6px;
}

.textSection {
    max-height: 65px;
    height: 100%;
    flex: 1;
    overflow: hidden;
    display: -webkit-box;
    line-height: 17px;
    line-break: anywhere;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
}

.text {
    height: 100%;
    color: #2c476c;
    font-size: 12px;
    word-break: break-word;
}

.footer {
    display: flex;
    justify-content: center;
    align-items: start;
    flex-direction: column;
    margin-top: 10px;
}

.footerText {
    font-family: 'Noto Sans Armenian', sans-serif !important;
    color: #2c476c;
    font-size: 14px;
}

.dateText {
    font-weight: 500;
    font-size: 12px;
}
.dateNums {
    margin-left: 5px;
}

@media (min-width: 1920px) {
    /* For Full HD displays */
    .responsive-image-container {
        width: 100px;
        height: 85px;
    }

    .text {
        font-size: 12px; /* Slightly larger font for Full HD */
    }
}

@media (max-width: 1280px) {
    /* For smaller displays like 720p */
    .responsive-image-container {
        width: 65px;
        height: 55px;
    }


    .textSection {
        max-height: 100px;
        flex: 1;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
    }

    .text {
        font-size: 12px; /* Smaller font for compact displays */
    }
}
