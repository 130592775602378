.confirmationModal {
    width: 480px;
    border-radius: 50px;
}

.confirmationModal .ant-modal > .ant-modal-content {
    border-radius: 50px !important;
}

.textContent {
    text-align: center;
    height: fit-content;
    margin-bottom: 2em;
}

.buttonsWrapper {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    margin-bottom: 16px;
}

.cancelButton {
    background-color: #ffde5a;
    border: none;
    color: #000000 !important;
    letter-spacing: 1px !important;
    font-weight: 400 !important;
}

.cancelButton:hover {
    background-color: #ffdF80 !important;
}

.deleteButton {
    background-color: #FF4015 !important;
    color: #fff !important;
    border: none !important;
    letter-spacing: 1px !important;
    font-weight: 400 !important;
}

.deleteButton:hover {
    background-color: #FF6B3B !important;
}

.cancelButton, .deleteButton {
    width: 170px;
    height: 35px;
    font-size: 1rem;
    font-weight: 600;
    border-radius: 30px;
}

@media (max-width: 1540px) {
    .confirmationModalClass {
        transform: scale(0.8) !important;
        transform-origin: center !important;
    }
}


@media (max-width: 1024px) {
    .confirmationModalClass {
        /*transform: scale(0.8) !important;*/
        /*transform-origin: center !important;*/
    }

    .cancelButton, .deleteButton {
        width: 130px;
        height: 35px;
        font-size: 1rem;
        font-weight: 600;
        border-radius: 30px;
    }
}

/* For small tablets / large phones (<= 768px wide) */
@media (max-width: 768px) {
    .confirmationModalClass {
        /*transform: scale(0.7) !important;*/
        /*transform-origin: center !important;*/
    }
}

/* For phones (<= 480px wide) */
@media (max-width: 480px) {
    .confirmationModalClass {
        /*transform: scale(0.6) !important;*/
        /*transform-origin: center !important;*/
    }
}

/* Very small screens (<= 360px wide) */
@media (max-width: 360px) {
    .confirmationModalClass {
        /*transform: scale(0.5) !important;*/
        /*transform-origin: center !important;*/
    }
}
