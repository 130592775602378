.map-screenshot-popup {
    display: flex;
    flex-direction: column;
    background-color: #fff;
    width: 100%;
    padding: 4px;
}


.map-screenshot-popup__header-title {
    font-weight: 700;
    font-size: 18px;
    letter-spacing: 1px;
    text-transform: uppercase;
    color: #2C476C
}

.map-screenshot-popup--minimized {
    min-width: 222px;
}

.map-screenshot-popup--expanded {
    min-width: 400px;
}

.map-screenshot-popup__header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    min-height: 36px;
}

.map-screenshot-popup__header-buttons {
    min-width: 72px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 15px;
}

.map-screenshot-popup__preview {
    width: 228px;
    height: 141px;
    margin: 10px 0;
    text-align: start;

    img {
        border-radius: 10px;
    }
}

.map-screenshot-popup__fields {
    display: flex;
    flex-direction: column;
}

.map-screenshot-popup__label {
    position: relative;
    width: 100%;

    .error_zone {
        margin-bottom: 0 !important;
    }

    color: #2c476c;
    font-weight: 400;
}

.map-screenshot-popup__label .map-screenshot-popup__counter {
    position: absolute;
    font-size: 11px;
    right: 10px;
    bottom: 5px;
    color: #2c476c50;
}

.map-screenshot-popup__label--description {
    .map-screenshot-popup__counter {
        bottom: -12px;
    }
}

.map-screenshot-popup__error {
    position: absolute;
    color: red;
    font-size: 12px;
    left: 0;
    bottom: 5px;
}

.map-screenshot-popup__label--description {
    .map-screenshot-popup__error {
        bottom: -12px;
    }
}

.map-screenshot-popup__textarea {
    width: 100%;
    margin: 5px 0;
    border-radius: 8px !important;
    overflow: auto;
    box-sizing: border-box;
    color: #2c476c !important;
    background: white;
}

.map-screenshot-popup__textarea:hover, .map-screenshot-popup__textarea:focus {
    border: 1px solid #156148 !important;
}


.map-screenshot-popup__textarea::-webkit-scrollbar-thumb {
    border-radius: 5px;
}

.map-screenshot-popup__textarea::-webkit-scrollbar {
    width: 5px;
}

.map-screenshot-popup__textarea::-webkit-scrollbar-track {
    border-radius: 15px;
    margin: 10px;
}

.map-screenshot-popup__actions {
    display: flex;
    flex-direction: column;
    gap: 25px;
    width: 100%;
    margin-top: 20px;
}

.map-screenshot-popup__action {
    display: flex;
    align-items: center;
    width: max-content;
    gap: 10px;
    cursor: pointer;
}

.map-screenshot-popup__icon-container {
    background-color: #2c476c1a;
    width: 40px;
    height: 40px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.map-screenshot-popup__icon {
    width: 20px;
    height: 20px;
}

.map-screenshot-popup__submit-button {
    min-width: 148px;
    height: 32px;
    background: #FFDE5A !important;
    color: black !important;
    border-radius: 38px;
    text-transform: uppercase;
    letter-spacing: 2.8px;
    box-shadow: none;
    font-size: 13px;
    font-family: "Noto Sans",sans-serif;
    /*margin-top: 15px;*/

    div {
        position: relative;
        bottom: 4px
    }
}

.map-screenshot-popup__submit-button:hover {
    background-color: #FFDE5AE6 !important;
}

.map-screenshot-popup__submit-button:disabled {
    background-color: #FFDE5AE6 !important;
    cursor: not-allowed;
    opacity: 0.6;
}

.map-screenshot-popup__form {
    width: 100%;
}

.map-screenshot-popup__submit {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    gap: 20px;
}


.map-screenshot-popup__confirm-button {
    width: 160px;
    height: 30px;
    font-size: 1rem;
    border-radius: 30px;
    letter-spacing: 1.5px;
    font-weight: 400;
    box-shadow: none;
    font-family: "Noto Sans",sans-serif;
}

.map-screenshot-popup__confirm-button:hover {
    opacity: .9;
}

.map-screenshot-popup__confirm-text {
    text-align: center;
    color: #2C476C;
    font-family: "Noto Sans",sans-serif
}

.map-screenshot-popup_confirm-modal {
    .ant-modal-confirm-btns {
        display: flex;
        flex-direction: row;
        justify-content: center;
        gap: 20px;
    }
}

@media (max-width: 1400px) {
    .map-screenshot-popup {
        padding: 10px;
        max-height: 470px;
        overflow-y: auto;
    }

    .map-screenshot-popup::-webkit-scrollbar {
        width: 4px;
    }

    .map-screenshot-popup::-webkit-scrollbar-track {
        border-radius: 20px;
        background: #EAEDF0;
    }

    .map-screenshot-popup::-webkit-scrollbar-thumb {
        background: #2c476c;
        background-clip: padding-box;
        border-radius: 10px;
    }
}

@media (min-width: 3000px) {
    .map-screenshot-popup {
        display: flex;
        flex-direction: column;
        background-color: #fff;
        width: 100%;
        padding: 20px;
    }

    .map-screenshot-popup--minimized {
        min-width: 546px;
    }

    .map-screenshot-popup--expanded {
        min-width: 800px;
    }

    .map-screenshot-popup__header {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }

    .map-screenshot-popup__header-buttons {
        display: flex;
        flex-direction: row;
        gap: 15px;
    }


    .map-screenshot-popup__header-title {
        font-size: 38px;
    }

    .map-screenshot-popup__preview {
        width: 428px;
        height: 241px;
        margin: 20px 0;
        text-align: start;
    }

    .map-screenshot-popup__fields {
        display: flex;
        flex-direction: column;
    }

    .map-screenshot-popup__label {
        position: relative;
        width: 100%;
    }

    .map-screenshot-popup__label .map-screenshot-popup__counter {
        position: absolute;
        font-size: 16px;
        bottom: -5px;
        color: #2c476c50;
    }

    .map-screenshot-popup__label--description {
        .map-screenshot-popup__counter {
            bottom: -20px;
        }
    }

    .map-screenshot-popup__textarea {
        width: 100%;
        /*border-radius: 20px !important;*/
    }

    .map-screenshot-popup__action {
        display: flex;
        align-items: center;
        width: max-content;
        gap: 20px;
        cursor: pointer;
    }

    .map-screenshot-popup__icon-container {
        background-color: #2c476c1a;
        width: 72px;
        height: 72px;
        border-radius: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .map-screenshot-popup__icon {
        width: 20px;
        height: 20px;
    }

    .map-screenshot-popup__submit-button {
        min-width: 340px;
    }

    .map-screenshot-popup__form {
        width: 100%;
    }

    .map-screenshot-popup__submit {
        display: flex;
        justify-content: center;
        margin-top: 20px;
        gap: 20px;
    }
}
