.bookmark-dashboards {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(216, 216, 216, .4);
    margin: 140px 30px 40px;
    padding: 30px 30px 50px;
    overflow: auto;
    display: flex;
    align-items: stretch;
    background-color: #2C476C;
    border-radius: 11px;
    flex-direction: column;
    overflow: hidden;
    height: calc(100% - 186px);
}
.bookmark-dashboards  .account-dashboards--content{
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(5,calc(20% - 16px));
    grid-template-rows: repeat(2,45%);
    gap: 20px;
    overflow: hidden;
}
.bookmark-empty-data{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.bookmark-empty-data h2{
    font-family: "sans-serif, Arial";
    font-style: normal;
    font-weight: 900;
    font-size: 22px;
    line-height: 19px;
    letter-spacing: 1px;
/* identical to box height */
    text-transform: uppercase;
    color: white;
}
/*.top-btns>div{*/
/*    display: flex;*/
/*    gap: 10px;*/
/*    margin: max(calc(100vh/56),5px);*/
/*}*/
.account-dashboards--pagination {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
.bookmark-dashboards ul.MuiPagination-ul li:first-child,ul.MuiPagination-ul li:last-child {
    background-color: #F3F2F7;
    border-radius: 50%;
    margin: 0px 10px;
}
.bookmark-dashboards ul.MuiPagination-ul li:nth-child(2) {
    border-bottom-left-radius: 20px;
    border-top-left-radius: 20px;
    -moz-border-radius-bottomleft: 20px;
    -moz-border-radius-bottomleft: 20px;
}

.bookmark-dashboards ul.MuiPagination-ul li:nth-last-child(-2n+2) {
    border-bottom-right-radius: 20px;
    border-top-right-radius: 20px;
    -moz-border-radius-bottomright: 20px;
    -moz-border-radius-topright: 20px;
}
.bookmark-dashboards ul.MuiPagination-ul li:not(:first-child,:last-child) {
    background-color: #D9D9D9;
    height: 30px;
}
.bookmark-dashboards ul.MuiPagination-ul li button{
    margin: 0px;
}
.bookmark-dashboards ul.MuiPagination-ul li:not(:first-child,:last-child) button{
    top: -1px;
}
.bookmark-dashboards ul.MuiPagination-ul li button.Mui-selected{
    background-color: #3985EC;
    color: #fff;
}
.account-dashboards--pagination .small{
    display: none;
}
.account-dashboards--pagination .medium{
    display: unset;
}
@media screen and (min-width: 900px) and (max-width: 1800px) {
    .account-dashboards--content {
        grid-template-columns: repeat(5,calc(20% - 10px)) !important;
        grid-template-rows: repeat(2,48%) !important;
        gap: 10px!important;
    }
    .top-btns > div {
        margin: 0;
    }

    .bookmark-dashboards {
        margin: 110px 15px 20px;
        padding: 10px 20px 35px;
        height: calc(100dvh - 120px);
        z-index: 2;
    }
    .bookmark-dashboards ul.MuiPagination-ul li:not(:first-child,:last-child){
        height: 25px;
    }
    .account-dashboards--pagination .small{
        display: unset;
    }
    .account-dashboards--pagination .medium{
        display: none;
    }
}
@media (max-width: 512px) {
    .bookmark-dashboards{
        margin: 20px 0 0 0 !important;
        padding: 0;
        width: 100vw;
        gap: 5px !important;
    }
    .account-dashboards--pagination{
        justify-content: center!important;
    }
    /*.top-btns {*/
    /*    padding-left: 80px !important;*/
    /*    margin-right: 10px !important ;*/
    /*}*/
    .bookmarks__main-tab {
        padding-right: 20px !important;
    }
}
@media (max-height: 512px) {
    .bookmark-dashboards{
        margin: 90px 0 0 0 !important;
        padding: 15px 0 0 0!important;
        gap: 0!important;
        border-radius: 0!important;
        width: 100vw;
    }
    .bookmark-dashboards ul.MuiPagination-ul li:not(:first-child,:last-child){
        height: 25px;
    }
    .bookmark-dashboards ul.MuiPagination-ul li button{
        min-width: 25px;
        height: 25px;
    }
    .account-dashboards--content .card-body{
        aspect-ratio: 6 / 3!important;
        padding: 10px;
        grid-template-rows: 18px max-content 2fr 1fr;
        overflow: hidden;
    }

}
