/* PopupForm.module.css */

/* Container Styles */
.container {
    display: flex;
    justify-content: flex-end;
    padding: 10px;
    height: auto;
    max-height: fit-content;
    box-sizing: border-box;
}
/* Card Styles */
.card {
    box-shadow: 0 4px 4px 0 #00000040 !important;
    width: 368px;
    border-radius: 12px;
    min-height: fit-content;
    max-height: inherit;
    z-index: 99999;
    background-color: #fff;
    overflow-y: auto; /* Enable vertical scrolling if content overflows */
}

.card :global(.ant-card-body)::-webkit-scrollbar-thumb {
    border-radius: 5px;
    background: #314769;
}

.card :global(.ant-card-body)::-webkit-scrollbar {
    width: 5px;
}

.card :global(.ant-card-body)::-webkit-scrollbar-track{
    border-radius: 15px;
    margin: 10px;
}

/* Card Header Styles */
.cardHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

/* Close Icon Styles */
.closeIcon, .deleteIcon {
    font-size: 16px;
    cursor: pointer;
}

/* Space Container Styles */
.spaceContainer {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    flex-wrap: nowrap; /* Allow wrapping on smaller screens */
    gap: 10px; /* Add space between buttons */
}

.ant-space-item > .ant-space-item {
    width: 50%;
}

/* Small Button Styles */
.smallButton {
    width: 35px;
    height: 35px; /* Ensure consistent height */
    background: #EAEDF0;
    border: none; /* Remove default border */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    cursor: pointer;
    transition: background 0.3s;
}

.smallButton:hover {
    background: #d4d7dc;
}

/* Button Container Styles */
.buttonContainer {
    display: flex;
    justify-content: center;
    margin-top: 15px;
    width: 100%;
}

.iconButtonWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    min-width: 32px;
    max-width: 32px;
    max-height: 32px;
    min-height: 32px;
}

.iconWrapper {
    aspect-ratio: 1/1;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    min-width: 32px;
    max-width: 32px;
    max-height: 32px;
    min-height: 32px;
    border-radius: 10px;
    border: none;
    transition: all 0.3s ease;
    /*outline: none;*/
    /*DONT CHANGE THE PERCENTAGE, ITS CAN CAUSE PROBLEMS WITH THE ICONS*/
    /*transform: scale(71.43%);*/
}

.noClickEffect:hover,
.noClickEffect:focus,
.noClickEffect:active {
    box-shadow: none;    /* Remove hover/focus/active shadow effect */
    background-color: inherit; /* Prevent background color changes */
}

.iconWrapper .ant-btn-icon {
    width: 32px;
    height: 32px;
    min-width: 32px;
    max-width: 32px;
    max-height: 32px;
    min-height: 32px;
}

.spriteIconWrapperMicro:hover {
    /*filter: invert(1);*/
}

/* Save Button Styles */
.saveButton {
    background-color: #FFDE5A;
    box-shadow: none;
    border: none;
    width: 130px;
    min-width: 130px;
    height: 31px;
    cursor: pointer;
    letter-spacing: 1px;
    color: #000;
    transition: background-color 0.2s;
}

.saveButton:hover {
    background-color: #FFDE5A !important;
    box-shadow: none !important;
    color: #000 !important;
    border: none !important;
}

.saveButton:disabled {
    background-color: #FFDE5A !important;
    box-shadow: none !important;
    color: #000 !important;
    border: none !important;
}

.saveButton:focus {
    background-color: #e6c200 !important;
    box-shadow: none !important;
    border: none !important;
}

/* Flex Container for Form Items */
.flexContainer {
    display: flex;
    align-items: center;
}

/* Flex Row for Aligning Buttons and Text */
.flexRow {
    display: flex;
    align-items: center;
}

.titleInput {
    box-shadow: none;
    height: 35px;
    min-height: 35px;
    max-height: 35px;
}

.microArticleFormPopupContainer .ant-input-data-count {
    margin-right: 5px !important;
    color: #2C476C50 !important;
}

.titleInput textarea {
    height: 35px;
    min-height: 35px;
    max-height: 35px;
    /*  for centering the text in the middle  */
    line-height: 25px;
    /******************************************/
    white-space: nowrap;
    box-shadow: none;
    overflow: hidden;
    text-overflow: ellipsis;
}

/* Input with Button Styles */
.inputWithButton {
    resize: none;
    width: 100%;
    flex-grow: 1;
    margin-right: 10px; /* Space between input and button */
}

/* Resizable Textarea */
.resizableTextarea {
    width: 100%;
    box-shadow: none;
    resize: none;
    max-height: 150px;
}

.resizableTextarea textarea{
    margin: 7px 0;
    /*width: 98.5%;*/
}

.formItemChangedAsterisk {}

.formItemChangedAsterisk .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
    content: '*';
    margin-right: 4px;
    color: red;
}

.formItemChangedAsterisk .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::after {
    content: none;
}

/* Margin Left Utility */
.marginLeft10 {
    margin-left: 10px;
}

.youtubeLinkContainer {
    display: flex;
    align-items: center;
    margin-left: 16px; /* Adjust spacing as needed */
}

.youtubeLinkInput {
    flex: 1;
}

/* Image Upload Section Styles */

/* Container for Image Previews */
.previewContainer {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; /* Prevent wrapping to enable horizontal scrolling */
    overflow-x: auto; /* Enable horizontal scrolling */
    overflow-y: hidden; /* Prevent vertical scrolling */
    gap: 0 0;
}

/* Hide scrollbar for Webkit browsers */
.previewContainer::-webkit-scrollbar {
    height: 5px;
    width: 100%;
}

.previewContainer::-webkit-scrollbar-track {
    background: #EAEDF0;
}

/* Individual Image Preview Item */
.previewItem {
    position: relative;
    width: 80px;
    height: 80px;
    border-radius: 10px;
    overflow: hidden;
    cursor: grab;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
    flex-shrink: 0; /* Prevent shrinking when container is too small */
}

.previewItem:active {
    cursor: grabbing;
}

/* Image within Preview Item */
.previewImage {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

/* Remove Icon on Image Preview */
.removeIcon {
    position: absolute;
    top: 4px;
    right: 4px;
    color: rgba(255, 255, 255, 0.85);
    background: rgba(0, 0, 0, 0.45);
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
    transition: background 0.3s;
}

.removeIcon:hover {
    background: rgba(0, 0, 0, 0.65);
}

.previewItem {
    position: relative;
    display: inline-block;
    margin: 5px 2px;
    cursor: pointer;
}

.previewImage {
    width: 80px;
    height: 80px;
    object-fit: cover;
    /*border-radius: 15px;*/
}

.removeIcon {
    position: absolute;
    top: 5px;
    right: 5px;
    color: rgba(255, 255, 255, 0.8);
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    padding: 2px;
    cursor: pointer;
}

.playIcon {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 45px;
    height: 45px;
    transform: translate(-50%, -50%);
    font-size: 24px;
    color: rgba(255, 255, 255, 0.8);
    pointer-events: none;
}

.iframeIconContainer {
    width: 100%;
    height: 100%;
    background-color: #f0f0f0; /* Gray background */
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 4px; /* Optional: to match other items' styles */
}

.iframeWrapper {
    width: 100%;
    height: 425px;
}

.codeIcon {
    width: 50%;
    height: 50%;
    color: #000000; /* Icon color */
}

.modalHeader {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}
.modalHeaderText {
    letter-spacing: 5px;
}

.modalContent {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.modalMedia {
    border-radius: 8px;
}

.modalButtonContainer {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.modalSaveButton {
    width: 170px;
    height: 30px;
    background-color: #FFDE5A;
    border: none;
    color: #000;
    border-radius: 38px;
    box-shadow: none;
    cursor: pointer;
    letter-spacing: 1px;
}

.modalSaveButton:hover {
    background-color: #FFDE5A !important;
    box-shadow: none !important;
    color: #000 !important;
    border: none !important;
}



.modalDeleteButton {
    width: 170px;
    height: 30px;
    background-color: #EB572C;
    border: none;
    border-radius: 38px;
    letter-spacing: 1px;
    box-shadow: none;
    color: #fff;
}

.environmentPopup {
    box-shadow: 0 4px 4px 0 #00000040 !important;
    width: 400px;
    display: flex;
    max-height: 430px;
    border-radius: 8px;
    border: 1px solid #2C476C50;
    background-color: white;
}

.popupLeft {
    border-right: 1px solid #f0f0f0;
    background-color: #E9ECF0;
    height: 100%;
    max-height: 310px;
    overflow: auto;
    border-radius: 8px 0 0 8px;
}

.popupRight {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%; /* Ensure the container takes full height */
    max-height: 310px;
    padding-left: 10px;
    border-radius: 0 8px 8px 0;
    overflow: auto;
}

.popupRight {
    display: flex;
    flex-direction: column;
    height: 100%; /* Ensure the container takes full height */
}

.topSection {
    /* Optional: Add padding or other styles if needed */
}

.iconsGrid {
    width: 100%;
    padding-right: 5px;
    display: grid;
    grid-template-columns: repeat(6, 40px);
    place-items: center;
    /*min-height: 100%;*/
}

.bottomSection {
    /* Optional: Add padding or other styles if needed */
    width: 100%;
}

.customIcon {
    width: 18px;
    height: 18px;
}

.isActiveIcon {
    filter: brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(315deg) brightness(104%) contrast(101%);
}
.bottomSectionSpace {
    width: 100%;
    margin: 5px 0;
    padding:0 10px 5px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.colorButton, .colorButton_selected {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
}

.colorButton_selected {
    border: 2px solid;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 4px; /* Adjust padding to control space between border and inner circle */
}

.colorButtonInner {
    background-color: currentColor;
    border-radius: 50%;
    width: 16px; /* Adjust size as needed */
    height: 16px;
}

.recentColorButton {
    border-radius: 50%;
    background-color: #D8D8D8;
    width: 100%;
    border: none;
    display: flex;
    justify-content:center;
    align-items: center;
    cursor: pointer;
}

.recentColorButton span {
    width: 100%;
    height: inherit;
    min-height: 100%;
    display: flex;
    justify-content:center;
}

.recentColorButton svg {
    width: 100%;
    height: inherit;
    min-height: 100%;
}

.searchInput {
    border: none;
    border-bottom: 1px solid #d9d9d9; /* Light gray bottom border */
    border-radius: 0; /* Remove rounded corners */
    padding: 4px 8px; /* Adjust padding as needed */
    outline: none; /* Remove default outline on focus */
    box-shadow: none; /* Remove any box shadow */
    margin: 10px 10px 10px 0;
    width: 95%;
    &:focus {
        border-bottom: 1px solid #1890ff; /* Blue bottom border on focus */
    }
}

.middleSection {
    margin-right: 13px;
    flex: 1 1 auto; /* Allows this section to grow and shrink as needed */
    overflow-y: auto; /* Enables vertical scrolling */
    min-height: 192px;
}


.iconItem {
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 32px;
    max-height: 32px;
    padding: 5px;
    margin-bottom: 5px;
}

.categoryList :global(.ant-list-items) {
    background-color: #E9ECF0;
    overflow: hidden;
}

.categoryList :global(.ant-list-item) {
    padding: 5px;
    border: none;
}

.emptyPlaceholder {
    min-height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.categoryItem {
    cursor: pointer;
    margin-left: 10px;
    padding: 2px;
    transition: background-color 0.3s;
    max-width: calc(100% - 5px);
    font-weight: normal;
}

.categoryItem:hover {
    font-weight: bold;
}

.categoryItem span:hover {
    font-weight: bold;
}

.categoryItem span {
    text-overflow: ellipsis;
    overflow: hidden;
}

.popoverWrapper {
    position: relative;
    max-height: 48px;
    max-width: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-left: 10px;
    width: 48px;
    height: 48px;
    border: none;
}

.toggleButton {
    height: 28px;
    max-height: 28px;
}

.iconPopover > .ant-popover-content > .ant-popover-inner {
    padding: 0;
}

.icon {
    transform: scale(0.3);
}

.modalMedia {
    width: 100%;
    height: 400px;
    object-fit: cover;
}

.iframeWrapper {
    width: 100%;
    height: 400px;
}

.modalImage {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    background-color: #2C476C1A;
    border-radius: 8px;
}

.modalVideo {
    width: 100%;
    max-height: 400px;
    object-fit: contain;
    background-color: #2C476C1A;
    border-radius: 8px;
}

/* Upload Button Styles */

@media (max-width: 1540px) {
    .captionModal, .webcamModal {
        transform: scale(0.8) !important;
        transform-origin: center !important;
    }
}


@media (max-width: 1024px) {
    .captionModal, .webcamModal {
        transform: scale(0.8) !important;
        transform-origin: center !important;
    }
}

/* For small tablets / large phones (<= 768px wide) */
@media (max-width: 768px) {
    .captionModal, .webcamModal {
        transform: scale(0.7) !important;
        transform-origin: center !important;
    }
}

/* For phones (<= 480px wide) */
@media (max-width: 480px) {
    .captionModal, .webcamModal {
        transform: scale(0.6) !important;
        transform-origin: center !important;
    }
}

/* Very small screens (<= 360px wide) */
@media (max-width: 360px) {
    .captionModal, .webcamModal {
        transform: scale(0.5) !important;
        transform-origin: center !important;
    }
}

@media (max-width: 1540px) {
    .container {
        will-change: transform;
        transform: scale(0.8) translateZ(0);
        transform-origin: top right;
        z-index: 99999;
        max-height: fit-content;
    }

    /*.ant-popover-inner:has(.environmentPopup) {*/
    /*    will-change: transform !important;*/
    /*    transform: scale(0.8) translateZ(0) !important;;*/
    /*    transform-origin: top right !important;;*/
    /*}*/

    .card {
        max-height: fit-content;
        min-height: auto;
    }
}
